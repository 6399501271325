<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="custom-toolbar-subdivision">
    <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <yo-button
      label="CRÉER"
      iconClass="fa fa-plus"
      (onClick)="openFournisseurDialog(undefined)"
      [disabled]="!canCreate()"
      tooltip="Créer un fournisseur">
    </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-popup
  [width]="700"
  [height]="'auto'"
  [showTitle]="true"
  [title]="deleteDialogTitle"
  [(visible)]="popupVisible"
  [resizeEnabled]="true"
  (onHiding)="closeDeleteDialog()"
>
  <div>
    <p>
      Êtes-vous sûr de vouloir supprimer ce fournisseur ?
    </p>
    <p>
      Cela entraînera également :
    </p>
    <ul>
      <li>les commandes de ce fournisseur</li>
      <li>les lignes des listes de besoins</li>
      <li>les stocks liés à ce fournisseur</li>
      <li>les articles liés à ce fournisseur</li>
      <li>l'éventuelle liaison avec les lots marchés</li>
      <li>les fournisseurs fils et toutes les données qui leurs sont rattachées</li>
    </ul>
    <p>
      Cette action est irréversible.
    </p>
  </div>
  <div class="custom-button-container">
    <button
      class="btn btn-primary"
      type="button"
      (click)="deleteObject()">
      Confirmer
    </button>
  </div>
</dx-popup>

<dx-data-grid
  [columnAutoWidth]="true"
  [dataSource]="fournisseursList"
  keyExpr="id"
  class="data-grid"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  columnResizingMode="widget"
>
  <dxo-filter-row
    [visible]="true"
    [applyFilter]="'contains'"
  ></dxo-filter-row>
  <dxi-column dataField="id"
              caption="Actions"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openFournisseurDialog(cell.row.data)"
                    [yoViewMode]="!gds.canModify(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoIconClass="fa fa-edit"
                    yoTooltip="Modifier le fournisseur"
    ></yo-cell-button>
    <yo-cell-button (yoNavigation)="showDeletePopup(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    [yoDisabled]="!gds.canModify(cell.row.data)"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer le fournisseur"
    ></yo-cell-button>
  </div>
  <dxi-column dataField="site.libelle"
              caption="Site"
              [allowFiltering]="true"
              [allowGrouping]="false"
              cellTemplate="sitesCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div *dxTemplate="let cell of 'sitesCellTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <dxi-column dataField="libelle"
              caption="Fournisseur"
              [allowFiltering]="true"
              [allowGrouping]="false"
              cellTemplate="libelleCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div *dxTemplate="let cell of 'libelleCellTemplate'">
    {{cell.row.data?.libelle | uppercase}}
  </div>


  <dxi-column dataField="filiales"
              caption="Filiales"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="filialesCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div class="filiales" *dxTemplate="let cell of 'filialesCellTemplate'">
    <yo-cell-button (yoNavigation)="openFiliale(cell.row.data,undefined)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-plus"
    ></yo-cell-button>
    <ng-container *ngFor="let filiale of cell.row.data.children">
      <span id="filiale" class="genlabel cursor" (click)="chooseFilialeAction(filiale)">{{filiale.libelle | uppercase}}
        ({{filiale.code}})</span>
      <dx-context-menu
        cssClass="custom-context-menu"
        [items]="filialeActions"
        target="#filiale"
        (onItemClick)="itemClick($event)"
        [showEvent]="{ name: 'dxclick', delay: 0 }"
      >
      </dx-context-menu>
    </ng-container>
  </div>


  <dxi-column dataField="code"
              caption="Code"
              [allowFiltering]="true"
              [allowGrouping]="false"
              cellTemplate="codesCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div *dxTemplate="let cell of 'codesCellTemplate'">
    {{cell.row.data?.code | uppercase}}
  </div>


  <dxi-column dataField="adresse"
              caption="Adresse"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="addressesCellTemplate"
              alignment='center'
  >
  </dxi-column>

  <div *dxTemplate="let cell of 'addressesCellTemplate'">
    {{cell.row.data?.adresse?.codePostal}} {{cell.row.data?.adresse?.ville}}
  </div>


  <dxi-column dataField="actif"
              caption="Actif"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actifCellTemplate"
              alignment='center'
  >
  </dxi-column>
  <div *dxTemplate="let cell of 'actifCellTemplate'">
    <dx-check-box [disabled]="true" [(ngModel)]="cell.row.data.actif">
    </dx-check-box>
  </div>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
  >
  </dxo-pager>

</dx-data-grid>

<!--DIALOG DE MISE À JOUR DU CATALOGUE ACHAT D'UN SECTEUR FOURNISSEUR-->
<yo-catalogue-achat-filiale></yo-catalogue-achat-filiale>

<!--DIALOG-EDITION FILIALE-->
<yo-filiale-form-dialog
  [displayDialog]="displayFormDialog"
  (onClose)="onCloseFormDialog()"
></yo-filiale-form-dialog>

<yo-fournisseur-siderbar></yo-fournisseur-siderbar>

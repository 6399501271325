import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProfilsService} from '../../core/services/entities/profils.service';
import {UtilsService} from '../../core/utils/utils.service';
import {ProfilDTO} from '../../core/dtos/profil-dto';
import {RoleDTO} from '../../core/dtos/role-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProfilSupplier} from './profils-resolver.service';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {UtilisateurService} from "../../core/utilisateur.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";
import {TagBoxComponent} from "../../shared/ui/tag-box/tag-box.component";

@Component({
  selector: 'yo-profils',
  templateUrl: './profils.component.html',
  styleUrls: ['./profils.component.scss']
})
export class ProfilsComponent implements OnInit, OnDestroy {

  @ViewChild("rolesTagBox") rolesTagBox: TagBoxComponent;

  title: string;
  subProfils: Subscription;
  profilList: ProfilDTO[];
  roles: RoleDTO[];
  displayProfilRoleDialog: boolean;
  isEditMode = false;
  dialogProfil: ProfilDTO;
  dialogErrMsgList: string[] = [];
  selectedRoles: RoleDTO[];
  pathFileProfils: string = HELP_FOLDERS.REFERENTIEL + '/profils';
  pathFileRoles: string = HELP_FOLDERS.REFERENTIEL + '/roles';

  constructor(private profilSvc: ProfilsService,
              private utilisateurSvc: UtilisateurService,
              private route: ActivatedRoute,
              public utils: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.subcriptionProfils();
    this.initTitle();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subProfils);
  }

  subcriptionProfils = (): void => {
    this.subProfils = this.route.data.subscribe((data: { profilSupplier: ProfilSupplier }) => {
      this.profilList = data.profilSupplier.profils;
      this.roles = data.profilSupplier.roles;
    });
  };

  addProfilDialog = (): void => {
    this.selectedRoles = [];
    this.isEditMode = false;
    this.dialogErrMsgList = [];
    this.displayProfilRoleDialog = true;
    this.dialogProfil = this.profilSvc.createEmptyDTO();
    this.selectedRoles.push(this.addRoleUtilisateur(this.roles));
    this.dialogProfil.roleList = this.selectedRoles;
  };

  onChangeProfil = (profil: ProfilDTO): void => {
    if (!this.utils.isNullOrEmpty(profil)) {
      this.isEditMode = true;
      this.dialogErrMsgList = [];
      this.displayProfilRoleDialog = true;
      this.dialogProfil = _cloneDeep(profil);
      this.selectedRoles = this.rolesTagBox.getSelectedItems();
    }
    this.initTitle();
  };

  closeDialog = (): void => {
    this.displayProfilRoleDialog = false;
  };

  addRoleUtilisateur = (roles: RoleDTO[]): RoleDTO => {
    return _find(roles, {'libelle': 'ROLE_UTILISATEUR'});
  };

  saveProfil = (): void => {
    this.dialogErrMsgList = this.validBeforeSave();
    if (this.utils.isCollectionNullOrEmpty(this.dialogErrMsgList)) {
      let isCreation = !this.dialogProfil.id;
      this.profilSvc.saveProfil(this.dialogProfil).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.dialogProfil = response.one;
          if (!isCreation) {
            const idx = this.profilList.findIndex(p => p.id === this.dialogProfil.id);
            this.profilList[idx] = this.dialogProfil;
          } else {
            this.profilList.push(this.dialogProfil);
          }
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le profil a été enregistré avec succès`);
          this.closeDialog();
        }
      });
    }
  };

  validBeforeSave = (): string[] => {
    let errMsgList: string[] = [];
    if (this.utils.isNullOrEmpty(this.dialogProfil.libelle)) {
      errMsgList.push('Le profil est obligatoire.');
    }
    if (this.utils.isNullOrEmpty(this.dialogProfil.description)) {
      errMsgList.push('La description est obligatoire.');
    }
    return errMsgList;
  };

  deleteProfil = async (profil: ProfilDTO): Promise<void> => {
    const result = confirm(`Supprimer le profil "${profil.libelle}" ?`, 'Suppression de profil');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.profilSvc.deleteProfil(profil.id).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le profil ${profil.libelle} a été supprimé avec succès`);
          const idx = this.profilList.findIndex(p => p.id === profil.id);
          this.profilList.splice(idx, 1);
        }
      });
    }
  };

  loadDataGrid = (): void => {
    this.utilisateurSvc.getProfilList().subscribe((response: ResponseWrapper<ProfilDTO>) => {
      this.profilList = response.resultList;
    });
  };

  /**
   * On ne peut effectuer d'actions sur les profils par defaut
   * @param profil
   */
  canDoActions = (profil: ProfilDTO): boolean => {
    if (!this.utils.isNullOrEmpty(profil)) {
      const profilLibelle = profil.libelle.toLowerCase();
      if (profilLibelle === 'administrateur' || profilLibelle === 'import' || profilLibelle === 'utilisateur') {
        return false;
      }
    }
    return true;
  };

  help = (): undefined => undefined;

  initTitle() {
    this.isEditMode ? this.title = "Modification du profil" : this.title = "Nouveau profil";
  }
}

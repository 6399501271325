<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">

      <!--SELECTION CONTRAT-->
      <p-dropdown #dropDownContrat [options]="gms?.contrats"
                  class="mg-l-15 mg-r-5"

                  title="Offre alimentaire"

                  appendTo="body"
                  [style]="{'min-width':'200px','max-width':'650px' }"
                  optionLabel="libelle"
                  showClear="true"
                  placeholder="Choisir une offre alimentaire..."
                  (onChange)="changePlanning($event,chooserPlanning.CONTRAT)"
                  [(ngModel)]="selectedContratMenu" filter="true">
        <!--template contrat sélectionné-->
        <ng-template let-item pTemplate="selectedItem" class="option-dropdown">

          <ng-container *ngIf="!utils.isNullOrEmpty(selectedContratMenu)">
            <label class="space no-overflow max-width-300 mg-b-0 font-style">
              <i class="fa fa-lock danger-color space"
                 *ngIf="menusToolbarSvc.isLockedContratMenu(selectedContratMenu)"
                 title="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"></i>
              <i class="fa fa-unlock save-color space"
                 *ngIf="!menusToolbarSvc.isLockedContratMenu(selectedContratMenu)"
                 title="Vous avez les droits pour créer des menus sur cette offre alimentaire"></i>

              {{selectedContratMenu.libelle | uppercase}}</label> <label
            class="font-12 floatright no-overflow max-width-300">
            <yo-site
              [siteId]="selectedContratMenu?.site?.id" [siteLibelle]="selectedContratMenu?.site?.libelle"></yo-site>
          </label>
          </ng-container>
        </ng-template>


        <!--template liste de contrats-->
        <ng-template let-contratMenu pTemplate="item">
          <div title="{{contratMenu.libelle | uppercase}} - {{contratMenu.site.libelle  | uppercase}}"


               tooltipStyleClass="tooltip-style">
            <label class="no-overflow max-width-300 mg-b-0 font-style">
              <span class="inline-block mg-r-5">
                <i class="fa fa-lock danger-color space"
                   *ngIf="!canModify(contratMenu)"
                   title="Vous n'avez pas les droits pour créer des menus sur cette offre alimentaire"></i>
                <i class="fa fa-unlock save-color space"
                   *ngIf="canModify(contratMenu)"
                   title="Vous avez les droits pour créer des menus sur cette offre alimentaire"></i>
              </span>
              {{contratMenu.libelle | uppercase}}
            </label>
            <span class="font-style floatright">
              <label class="no-overflow max-width-300">
                      <yo-site [siteId]="contratMenu.site.id"
                               [siteLibelle]="contratMenu.site.libelle"></yo-site>
              </label>
          </span>
          </div>
        </ng-template>
      </p-dropdown>

      <!--SELECTION PRESTATIONS-->
      <p-dropdown [options]="prestations"
                  class="mg-r-5"
                  #dropDownPrestation
                  title="Prestation"

                  appendTo="body"
                  [style]="{'min-width':'50px','max-width':'250px'}"
                  optionLabel="libelle"
                  showClear="true"
                  placeholder="Choisir une prestation..."
                  (onChange)="changePlanning($event,chooserPlanning.PRESTATION)"
                  [(ngModel)]="selectedPrestation" filter="true">


        <ng-template let-item pTemplate="selectedItem">
          <span class="mg-r-5 font-style">{{item.libelle}}</span>

        </ng-template>
        <ng-template let-item pTemplate="item">
          <span class="mg-r-5 font-style">{{item.libelle}}</span>
        </ng-template>

      </p-dropdown>

      <!--SELECTION RÉGIME-->
      <p-dropdown [options]="regimes"
                  class="mg-r-5"
                  #dropDownRegime
                  title="Régimes"

                  appendTo="body"
                  [style]="{'min-width':'50px','max-width':'250px'}"
                  optionLabel="libelle"
                  showClear="true"
                  (onChange)="changePlanning($event,chooserPlanning.REGIME)"
                  placeholder="Choisir un régime..."
                  [(ngModel)]="selectedRegime" filter="true">
        <ng-template let-item pTemplate="selectedItem">

          <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item.referent"
             title="Régime référent"
             ></i>
          <span class="font-style">{{item.libelle}}</span>
        </ng-template>
        <ng-template let-item pTemplate="item">

          <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="item.referent"
             title="Régime référent"
             ></i>
          <span class="font-style">{{item.libelle}}</span>
        </ng-template>
      </p-dropdown>

      <!--CALENDRIER-->
      <i class="fas fa-chevron-left cursor" (click)="goToPreviousDate()" title="Période précédente"
          ></i>
      <span style="margin-left:2px;margin-right:2px">
  <p-calendar
    dateFormat="dd/mm/yy"
    firstDayOfWeek="1"
    #calendar
    [numberOfMonths]="2"
    [readonlyInput]="true"
    [(ngModel)]="selectedStartDateMenus"
    (onSelect)="yoStartDateMenusChange($event)"
    (onFocus)="getMonthMenusCompoDates($event)"
    (onMonthChange)="getMonthMenusCompoDates($event)"
    (onYearChange)="getMonthMenusCompoDates($event)"
    [disabledDays]="disabledDays"
    [minDate]="dateMin"
    [locale]="fr"
    [showIcon]="false"
    [showWeek]="true">
  <ng-template pTemplate="date" let-date>
    <!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI ONT DES MENUS COMPOS-->
    <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                   [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
  </ng-template>
</p-calendar>
    </span>
      <i class="fas fa-chevron-right cursor mg-r-5" (click)="goToNextDate()" title="Période suivante"
          ></i>

      <!--DUPLICATION DES MENUS-->
      <yo-button class="mg-r-5" tooltip="Dupliquer des menus"
                 (onClick)="openDuplicationDialog()"
                 [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)"
                 iconClass="fas fa-copy">
      </yo-button>

      <!--DIALOGUE D'IMPRESSION DES MENUS-->
      <!--    <yo-button class="mg-r-5" [disabled]="printButtonDisabled()" tooltip="Impression du menu" (onClick)="openPrintDialog()" iconClass="fas fa-print"></yo-button>-->
      <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
      <yo-button
        iconClass="fas fa-print"
        class="mg-r-5"
        (onClick)="printMenu.toggle($event)"
        tooltip="Impression"
        [disabled]="isDisabledActionButton()">
      </yo-button>

      <!--GEMRCN-->
      <p-menu appendTo="body" #analyseMenu [popup]="true" [model]="itemsAnalyseMenu"></p-menu>
      <yo-button class="mg-r-5"
                 tooltip="Analyses"
                 (onClick)="analyseMenu.toggle($event)"
                 iconClass="fas fa-chart-line"
                 [disabled]="isDisabledActionButton()">
      </yo-button>

      <!--VALIDATION DES MENUS-->
      <yo-button class="mg-r-5"
                 iconClass="far fa-calendar-check"
                 tooltip="Envoi des menus sur les PLC associés à cette prestation"
                 (onClick)="openValidationMenu()"
                 [disabled]="isDisabledBySelectionOrEnvironnement(selectedContratMenu?.site?.id)">
      </yo-button>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <!--afficher/cacher les famnut, allergenes, appellations, effectifs... -->
      <ng-container *ngIf="prefsUser">
        <ng-container *ngTemplateOutlet="tplSettings; context:{}">
        </ng-container>
      </ng-container>

      <!--TEMPLATE CASES À COCHER PARAMÉTRAGE-->
      <ng-template #tplSettings>

        <yo-button (onClick)="menusToolbarSvc.openSettings()"
                   class="mg-r-15"
                   iconClass="fas fa-wrench"
                   tooltip="Réglages du planning">
        </yo-button>

      </ng-template>
    </div>
  </dxi-item>
</dx-toolbar>

<yo-validation-menu></yo-validation-menu>

<!--DIALOG DE DUPLICATION DES MENUS-->
<yo-duplication></yo-duplication>

<!--DIALOG PRINT COUT MATIERE-->
<yo-print-cout-matiere
  [contratsMenuConvive]="selectedPrestation"
  [selectedStartDateMenus]="selectedStartDateMenus"
  [selectedRegime]="selectedRegime"
  [regimeList]="regimes"
  [repasList]="mp2Svc.planningMenus ? mp2Svc.planningMenus.repasList : undefined">
</yo-print-cout-matiere>

<!--DIALOG PRINT BILAN ALLERGENE-->
<yo-print-bilan-allergene
  [selectedStartDateMenus]="selectedStartDateMenus"
  [contratsMenuConvives]="[selectedPrestation]"
  [regimeList]="regimes">
</yo-print-bilan-allergene>

<yo-print-menu
  [contratsMenuConvives]="[selectedPrestation]"
  [regimeList]="regimes"
  [selectedRegime]="selectedRegime"
  [selectedStartDateMenus]="selectedStartDateMenus">
</yo-print-menu>



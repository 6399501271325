import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {UtilsService} from '../../../../core/utils/utils.service';
import {DialogMsgSupplier} from '../../../../core/suppliers/dialog-msg-supplier';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {SiteService} from "../../../../core/site.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {Subscription} from "rxjs";
import {SecteursService} from "../../../../core/services/entities/secteurs.service";
import {SecteurDTO} from "../../../../core/dtos/secteur-dto";
import {TagBoxComponent} from "../../../../shared/ui/tag-box/tag-box.component";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {SecteurFournisseurDTO} from "../../../../core/dtos/secteurfournisseur-dto";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'yo-fournisseur-fiche-identite',
  templateUrl: './fournisseur-fiche-identite.component.html',
  styleUrls: ['./fournisseur-fiche-identite.component.scss']
})
export class FournisseurFicheIdentiteComponent implements OnInit {
  @ViewChild("secteursTagbox") secteursTagBox: TagBoxComponent;
  @ViewChild("siteLookup") siteLookup: LookUpComponent;
  @ViewChild("fournisseurForm") fournisseurForm!: NgForm;

  siteHasToBeSelected: boolean = false;
  secteursHaveToBeSelected: boolean = false;
  secteurFournisseurList: any[] = [];
  subSites: Subscription;
  fullScreen: boolean = true;
  secteursList: SecteurDTO[];
  selectedSite: number;
  selectedSecteurs: number[];
  sitesList: SiteDTO[];
  fournisseur: FournisseurDTO;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseur-edition';

  constructor(public fournisseurSvc: FournisseurService,
              public gestionFournisseurScv: GestionFournisseurService,
              public utils: UtilsService,
              private toastSvc: ToastService,
              private sitesSvc: SiteService,
              private secteurSvc: SecteursService,
              @Inject('dynamicData') public fournisseurData: FournisseurDTO) {
  }

  ngOnInit() {
    this.initSecteurs();
    this.initSites();
    this.initFournisseur();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSites);
  }


  saveFicheIdentite = (): void => {

    this.siteHasToBeSelected = false;
    this.secteursHaveToBeSelected = false;

    if (this.siteLookup.getSelectedItem() && this.secteursTagBox.getSelectedItems().length !== 0) {
      this.fournisseur.francoDePortCout = this.fournisseur.francoDePortCout ? this.fournisseur.francoDePortCout : 0;
      this.fournisseur.francoDePortTva = this.fournisseur.francoDePortTva ? this.fournisseur.francoDePortTva : 20;
      this.fournisseur.adresse.entityName = this.fournisseurSvc.getEntityName();

      const fournisseurClean = this.getFournisseurClean();
      fournisseurClean.site = this.utils.getLightSite(this.siteLookup.getSelectedItem())

      if (this.utils.isCollectionNullOrEmpty(this.secteursTagBox.getSelectedItems())) {
        fournisseurClean.secteurFournisseurList = [];
      } else {
        const selectedSecteursObjects = this.secteursList.filter((secteurAllProps => this.secteursTagBox.getSelectedItems().some(secteur => secteur.id === secteurAllProps.id)));
        selectedSecteursObjects.map(secteur => {
          const secteurFournisseur = new SecteurFournisseurDTO(this.fournisseur.id, secteur);
          this.secteurFournisseurList.push(secteurFournisseur);
        });
        fournisseurClean.secteurFournisseurList = this.secteurFournisseurList;
      }

      this.gestionFournisseurScv.saveFournisseur(fournisseurClean).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {

          this.fournisseur = response.one;
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement du fournisseur "${this.fournisseur.libelle.toUpperCase()}" réalisé avec succès`);

          // annoncer que la fiche d'identite a été sauvegardée. La grille des unites de prod doit être abonnée pour faire la mise à jour
          this.gestionFournisseurScv.announceRefreshGrid(this.fournisseur);

          // mise à jour de la liste des menus
          this.gestionFournisseurScv.announceFournisseur(this.fournisseur);
        }
      });
    }
  }

  isFormValid = (): boolean => {
    return this.siteLookup?.getSelectedItem() && this.secteursTagBox?.getSelectedItems().length !== 0 && this.fournisseurForm?.valid;
  }


  help = (): DialogMsgSupplier => undefined;

  private initFournisseur = ():void => {
    if (this.fournisseurData) {
      this.fournisseur = this.fournisseurData;
      if (this.fournisseurData.id !== 0) {
        this.initSelectedSecteurs();
        this.selectedSite = this.fournisseur.site.id
      } else {
        this.fournisseur.actif = true;
        this.fournisseur.francoDePortActif = false;
        this.fournisseur.adresse = new AdresseDTO(null, 0);
      }
    }
  }

  private getFournisseurClean = () => {
    return JSON.parse(JSON.stringify(this.fournisseur))
  }

  private initSelectedSecteurs = ():void => {
    this.selectedSecteurs = this.fournisseur.secteurFournisseurList.map(secteur => secteur.secteur.id);
  }

  initSites = (): void => {
    this.subSites = this.sitesSvc.getListeSitesActifs().subscribe((sites) => {
      if (sites)
        this.sitesList = sites;
      else
        this.sitesList = [];
    });
  };

  private initSecteurs = ():void => {
    this.secteursList = this.secteurSvc.secteurEnvironnement;
  }
}

<dx-popup
  title="Préférences"
  [(visible)]="displayPreferences"
>
  <dx-scroll-view
    id="scrollview-preferences"
    showScrollbar="always"
  >

    <p>
      Utilisateur actuel : {{auth2Svc?.utilisateur?.prenom | titlecase}} {{auth2Svc?.utilisateur?.nom | titlecase}}
      <br/>
      Profil(s) associé(s) : <strong>{{auth2Svc?.utilisateur?.profil}}</strong>
    </p>

    <div class="title-ca">Au démarrage de Datameal Online</div>
    <div class="container-preference">
      <div class="dialog-line">
        <div class="preference-choices">
          <div *ngFor="let d of displays">
            <input [id]="d.key" type="radio" class="radio-button" name="selectedDisplay" [value]="d"
                   [(ngModel)]="selectedDisplay"/>  <label [for]="d.key">{{ d.value }} </label>
          </div>
        </div>
      </div>
    </div>

    <div class="title-ca">Notifications</div>
    <div class="container-preference">
      <div class="dialog-line">
        <div class="preference-choices">
          <dx-check-box name="enableNotification" [(ngModel)]="enableNotification"
                        text="Activer les notifications"></dx-check-box>
        </div>
      </div>
    </div>


    <div class="title-ca">Emails</div>
    <div class="container-preference">
      <div class="dialog-line">
        <div class="preference-choices">
          <dx-check-box name="enableCopyCarbone" [(ngModel)]="enableCopyCarbone"
                        text="Envoi systématique d'une copie carbone (CC)"></dx-check-box>
        </div>
      </div>
    </div>

    <div *ngIf="hasSuperAdmin" class="title-ca">Calcul automatique du prix des plats</div>
    <div class="container-preference">
      <div class="dialog-line">
        <div class="preference-choices">
          <label>Veuillez indiquer pour chaque traitement indiqué ci-dessous, le déclenchement du calcul du prix des
            plats
            à la fin de des derniers : </label>
          <ul>
            <li>
              <dx-check-box name="saveItem" [(ngModel)]="saveItem" text="Enregistrement d'un article"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="deleteItem" [(ngModel)]="deleteItem" text="Suppression d'un article"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="selectManuallyItemPrefered" [(ngModel)]="selectManuallyItemPrefered"
                            text="Sélection manuelle d'un article préféré"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="addForbiddenFood" [(ngModel)]="addForbiddenFood"
                            text="Ajout d'une denrée interdite"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="deleteForbiddenFood" [(ngModel)]="deleteForbiddenFood"
                            text="Suppression d'une denrée interdite"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="replaceRawFood" [(ngModel)]="replaceRawFood"
                            text="Remplacement de denrée"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="duplicateProducts" [(ngModel)]="duplicateProducts"
                            text="Duplication d'un produit"></dx-check-box>
            </li>
            <li>
              <dx-check-box name="importXlsItems" [(ngModel)]="importXlsItems"
                            text="Import Excel des articles"></dx-check-box>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button iconClass="fas fa-save" buttonClass="cta-success" (onClick)="savePreferences()"
                   tooltip="Enregistrer les préférences"></yo-button>
        <yo-button buttonClass="cta-inner-delete " iconClass="fas fa-times"
                   (onClick)="closePreferences()"
                   tooltip="Fermer"></yo-button>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>



import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {Title} from '@angular/platform-browser';
import {FournisseurDTO} from '../../../core/dtos/fournisseur-d-t-o';
import {GestionFournisseurService} from '../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {FournisseurService} from '../../../core/services/entities/fournisseur.service';
import {FournisseurFicheIdentiteComponent} from "./fournisseur-fiche-identite/fournisseur-fiche-identite.component";
import {
  FournisseurCatalogueArticleComponent
} from "./fournisseur-catalogue-article/fournisseur-catalogue-article.component";
import {FournisseurFilialeGridComponent} from "./fournisseur-filiale-grid/fournisseur-filiale-grid.component";

@Component({
  selector: 'yo-fournisseur-siderbar',
  templateUrl: './fournisseur-siderbar.component.html',
  styleUrls: ['./fournisseur-siderbar.component.scss']
})
export class FournisseurSiderbarComponent implements OnInit, OnDestroy {

  fullScreen: boolean = false;
  title: string = "Création d'un fournisseur"
  isPopUpDisplayed: boolean = false;
  subFournisseur: Subscription;

  fournisseur: FournisseurDTO;
  navLinks: any[];

  dynamicInjector: Injector;

  constructor(private fournisseurSvc: FournisseurService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private titleSvc: Title,
              private utils: UtilsService,
              private injector: Injector) {
  }


  ngOnInit() {
    this.initNavLinks();
    this.fournisseurSubscription();
  }

  fournisseurSubscription = ():void => {
    this.subFournisseur = this.gestionFournisseurSvc.openFournisseurDialog$.subscribe((data: FournisseurDTO) => {
      if (!data) {
        this.fournisseur = new FournisseurDTO()
        this.title = "Création d'un fournisseur"
      } else {
        this.fournisseur = {...data};
        this.title = `Modifier le fournisseur ${this.fournisseur.libelle} du site ${this.fournisseur.site.libelle}`
      }
      this.isPopUpDisplayed = true;
      this.initNavLinks();
      this.createInjector();
    });
  };

  private createInjector = ():void => {
    this.dynamicInjector = Injector.create({
      providers: [{provide: 'dynamicData', useValue: this.fournisseur}],
      parent: this.injector,
    });
  }

  private initNavLinks = ():void => {
    this.navLinks = [];
    if (this.utils.isNullOrEmpty(this.fournisseur) || this.fournisseur.id === 0) {
      this.fournisseur = new FournisseurDTO();
      this.fournisseur.id = 0;
    }
    this.navLinks = [];
    this.navLinks.push({
      text: `Fiche d'identité`,
      component: FournisseurFicheIdentiteComponent
    });

    if (this.utils.isNumberGt0(this.fournisseur.id)) {
      this.navLinks.push({
        text: `Catalogue articles`,
        component: FournisseurCatalogueArticleComponent
      });
      this.navLinks.push({
        text: `Filiales`,
        component: FournisseurFilialeGridComponent
      });
    }
  };

  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subFournisseur);
  };

  closeDialog = ():void => {
    this.navLinks = [];
    this.isPopUpDisplayed = false;
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }
}

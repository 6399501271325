import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {ValueDTO} from '../../../core/dtos/value-dto';
import {MenuItem} from 'primeng/api';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {
  BCF_MAIL_STATUT,
  BCF_STATUT,
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  RECEPTION_STATUT
} from '../../../core/constants';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {cloneDeep as _cloneDeep, uniqBy as _uniqBy} from 'lodash';
import {switchMap} from 'rxjs/operators';
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {DomSanitizer} from '@angular/platform-browser';
import {saveAs as fs_saveAs} from 'file-saver';
import {BonReceptionDTO} from '../../../core/dtos/bon-reception-dto';
import {BonReceptionService} from '../../../core/services/entities/bon-reception.service';
import {Menu} from 'primeng/menu';
import {BoncfService} from "../../../core/services/entities/boncf.service";
import {MailHistoService} from "../../../core/services/entities/mail-histo.service";
import {DxDataGridComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {of, Subscription} from "rxjs";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-bc-receptions',
  templateUrl: './bc-receptions.component.html',
  styleUrls: ['./bc-receptions.component.scss']
})
export class BcReceptionsComponent implements OnInit, OnDestroy {

  BONCF_MAIL_STATUT = BCF_MAIL_STATUT;
  receptionStatutCode = RECEPTION_STATUT;

  commandesReceptionnees: BonCfDTO[] = [];
  selectedBonCfs: BonCfDTO[] = [];
  totalRecords = 0;
  loadingTable: boolean = false;

  isDisabledGobackOption: boolean = false;

  // dialog edit
  bonCf: BonCfDTO;
  lignesBonCf: BonCfDetailDTO[] = [];
  displayEditDialog: boolean = false;
  displayDialogArticlesReception: boolean = false;
  dialogTitle = 'Détails';

  // items pour le filtre de sélection des listes de besoin
  filterItemsListeBesoin: ValueDTO[];
  // items pour le filtre de sélection des unités de production
  filterItemsUdp: ValueDTO[];
  // items pour le filtre de sélection des fournisseurs
  filterItemsFournisseur: ValueDTO[] = [];

  filterDatesLivraisons: Date[]; // Merci de le laisser undefined ! p-calendar fonctionne mal avec un tableau vide

  fr: any;

  nextSteps: MenuItem[] = [];

  selectedBonReception: BonReceptionDTO;
  /**
   * Selection des actions sur le bon de réception
   */
  brlActions: MenuItem[] = [
    {
      label: 'Voir articles',
      icon: 'fas fa-th-large',
      command: (event) => this.bonReceptionSvc.announceBonReceptionGridDxView(this.selectedBonReception)
    },
  ];

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  listesBesoinsIdsSelected: number[] = [];
  udpIdsSelected: number[] = [];
  fournisseursIdsSelected: number[] = [];

  subMailsBonCfEnvoyes: Subscription;

  isActionDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/bons-receptions';

  selectAll: boolean = false;

  constructor(public auth2Svc: Auth2Service,
              public commandesSvc: CommandesService,
              private bonReceptionSvc: BonReceptionService,
              private bonCfSvc: BoncfService,
              private gds: GenericDatagridService,
              public wkfSvc: WorkflowsService,
              public utils: UtilsService,
              public mailHistoSvc: MailHistoService,
              public domSanitizer: DomSanitizer,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }


  ngOnInit() {
    this.loadingTable = true;
    this.fr = DATEPICKER_FR;
    this.initMenuEtape();
    this.initData();
    this.initCustomStore();
    this.mailsBonCfEnvoyesSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subMailsBonCfEnvoyes);
  }

  mailsBonCfEnvoyesSubscription: () => void = () => {
    this.subMailsBonCfEnvoyes = this.commandesSvc.mailsBonCfEnvoyes$
      .pipe(
        switchMap(bonsCfDTO => {
          return this.gds.search(this.commandesSvc.filterStatusMails(bonsCfDTO));
        }),
        switchMap(response => {
          this.commandesSvc.updateStatusBonsCommandesFromRefresh(response.resultList, this.commandesReceptionnees, true);
          return of(response.resultList);
        }),
        switchMap((mailsEnvoyes) => this.commandesSvc.refreshStatusMailsPeriodically(mailsEnvoyes, this.commandesReceptionnees))
      )
      .subscribe(response => {
        const mails = _uniqBy(response.resultList, mail => mail.extraInfos);
        this.commandesSvc.updateStatusBonsCommandesFromRefresh(mails, this.commandesReceptionnees, false);
        this.grid.instance.refresh();
      });
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const ssWrapper = new SearchSupplierWrapper();
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        ssWrapper.filtersMap['statutCode'] = new SearchSupplier(BCF_STATUT.COMMANDE_RECEPTIONNEE);
        if (!this.utils.isCollectionNullOrEmpty(this.filterDatesLivraisons)) {
          const startDate = this.filterDatesLivraisons[0].getTime();
          let stopDate = _cloneDeep(startDate);
          if (this.filterDatesLivraisons[1]) {
            stopDate = this.filterDatesLivraisons[1].getTime();
          }
          ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
          ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
        }

        if (this.listesBesoinsIdsSelected && this.listesBesoinsIdsSelected.length)
          ssWrapper.filtersMap['listesBesoins'] = new SearchSupplier(undefined, this.listesBesoinsIdsSelected);
        if (this.udpIdsSelected && this.udpIdsSelected.length)
          ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, this.udpIdsSelected);
        if (this.fournisseursIdsSelected && this.fournisseursIdsSelected.length)
          ssWrapper.filtersMap['ffs'] = new SearchSupplier(undefined, this.fournisseursIdsSelected);

        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id' && this.selectAll) {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.commandesSvc.searchCommandes(ssWrapper, urlPaginationParams).toPromise().then(response => {
          this.commandesReceptionnees = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }


  initData = (): void => {
    this.commandesSvc.getFilterItemsListeBesoin(BCF_STATUT.COMMANDE_RECEPTIONNEE).subscribe(response => {
      this.filterItemsListeBesoin = response.resultList;
    });

    this.commandesSvc.getFilterItemsUdp(BCF_STATUT.COMMANDE_RECEPTIONNEE).subscribe(response => {
      this.filterItemsUdp = response.resultList;
    });

    this.commandesSvc.getFilterItemsFfs(BCF_STATUT.COMMANDE_RECEPTIONNEE).subscribe(response => {
      this.filterItemsFournisseur = response.resultList;
    });
  };

  onChangeFilterDateLivraison = (): void => {
    if (this.grid)
      this.grid.instance.refresh();
  };

  onChangeFilterFournisseurs = ($event): void => {
    if (this.grid) {
      this.fournisseursIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterUdps = ($event): void => {
    if (this.grid) {
      this.udpIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterListesBesoins = ($event): void => {
    if (this.grid) {
      this.listesBesoinsIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  initMenuEtape = (): void => {
    this.nextSteps = [
      {
        label: `Passer les commandes sélectionnées à l'étape suivante :`,
        items: [
          {
            label: 'Facturées',
            icon: 'fas fa-angle-double-right',
            command: (event) => this.updateToCommanToNextStep(BCF_STATUT.COMMANDE_FACTUREE)
          }
        ]
      }
    ];
  };

  updateToCommanToNextStep = (statut: string): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesReceptionnees.find(p => id === p.id));
        this.commandesSvc.updateCommandesStatut(selectedBonCfs.map(b => b.id), statut).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
  };

  openObject = (bonCf: BonCfDTO): void => {
    this.bonCf = undefined;
    this.commandesSvc.getLignesCommandeFournisseur(bonCf.id).subscribe(response => {
      this.lignesBonCf = [];
      if (!this.utils.isResponseSupplierError(response)) {
        this.displayEditDialog = true;
        this.dialogTitle = this.commandesSvc.getCfTitle(bonCf);
        this.lignesBonCf = response.resultList;
        this.bonCf = _cloneDeep(bonCf);
      }
    });
  };

  printPDFCommandes = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesReceptionnees.find(p => id === p.id));
        this.commandesSvc.printPDFCommandes(selectedBonCfs).subscribe(response => {
          let blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, 'commandes-receptionnees.pdf');
        });
      });
  };

  chooseBrlAction: (menu: Menu, $event, bonReception: BonReceptionDTO) => void = (menu: Menu, $event, bonReception: BonReceptionDTO) => {
    this.selectedBonReception = bonReception;
    menu.show($event);
  };

  getMontantReceptionHT = (bonCfDTO: BonCfDTO): number => {
    if (bonCfDTO.bonReceptionList.length === 0) {
      return 0;
    } else {
      return bonCfDTO.montantHTReception + bonCfDTO.francoDePortReception;
    }
  };

  help = (): undefined => undefined;

  isDisabledGoBackToCommandeAReceptionner = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        let isDisabled = false;
        if (rowsSelected.length) {
          const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesReceptionnees.find(p => id === p.id));
          selectedBonCfs.forEach(bcf => {
            let isMovableBcf = false;
            bcf.bonReceptionList.forEach(br => {
              if (br.receptionStatut.code !== this.receptionStatutCode.TERMINE) {
                isMovableBcf = true;
              }
            });
            if (!isMovableBcf) {
              isDisabled = true;
            }
          });
        } else {
          isDisabled = true;
        }
        this.isDisabledGobackOption = isDisabled;
        this.initMenuEtape();
      });
  };

  openHistoriqueMails = (bonCf: BonCfDTO): void => this.bonCfSvc.announceHistoriqueMailBonCf(bonCf);

  onBonCommandSaved = (): void => {
    this.grid.instance.refresh();
  };

  cellClick = (e: any) => this.selectAll = (e.rowType === 'header');
}

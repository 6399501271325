<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="custom-button-container-left">
      <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        label="AJOUTER"
        iconClass="fa fa-plus"
        [tooltip]="'Ajouter un nouveau fournisseur au groupe'"
        (onClick)="openAddFournisseur()"
        [disabled]="!canAdd()">
      </yo-button>
      <yo-button iconClass="fas fa-trash " buttonClass="cta-delete"
                 [disabled]="utils.isCollectionNullOrEmpty(selectedFournisseurs)"
                 tooltip="Enlever des fournisseurs"
                 (onClick)="removeFournisseurs(selectedFournisseurs)">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<div class="dialog-line">
  <dx-data-grid
    [dataSource]="groupeAchatFournisseurList"
    keyExpr="id"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [(selectedRowKeys)]="selectedFournisseurs"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>

    <dxo-selection
      selectAllMode="true"
      [showCheckBoxesMode]="'always'" mode="multiple">
    </dxo-selection>


    <dxi-column dataField="fournisseur.site" alignment="left" caption="Site"
                [allowFiltering]="false" [allowSorting]="false"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="fournisseur.libelle" caption="Fournisseur" [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="fournisseur.code" caption="Code"  [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="ratioPrixUF" caption="Ratio Prix UF"  [allowFiltering]="false">
    </dxi-column>


    <!--    TEMPLATE ENGAGEMENT PRIX-->
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteLibelle]="cell?.data?.fournisseur?.site?.libelle"
               [siteId]="cell?.data?.fournisseur?.site?.id">
      </yo-site>
    </div>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="false"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Fournisseur(s)">
    </dxo-pager>

  </dx-data-grid>
</div>
<yo-dialog-add-fournisseur></yo-dialog-add-fournisseur>




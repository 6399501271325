import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DxTagBoxComponent} from "devextreme-angular";

@Component({
  selector: 'yo-tagbox',
  templateUrl: './tag-box.component.html',
  styleUrls: ['./tag-box.component.scss']
})
export class TagBoxComponent implements OnInit {

  @Input() placeholder: string = "Sélection, recherche...";
  @Input() data: any[] = [];
  @Input() enableSearch: boolean = true;

  /**
   * Valeur à afficher
   */
  @Input() displayValue: string = "libelle";

  /**
   * Liste des clefs (et non des objets) sélectionnées par défaut
   */
  @Input() selectedItems: any[] = [];

  /**
   * Clef de distinction des objets
   */
  @Input() key: string = "id";

  @Output() onValueChange = new EventEmitter;

  @ViewChild("tagbox") tagBox: DxTagBoxComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  getSelectedItems = (): any => {
    return this.tagBox.selectedItems;
  }

  valueChanged(data) {
    this.onValueChange.emit(data.value);
  }
}

<dx-popup
  [showTitle]="true"
  title="Ajouter des points de livraison"
  [(visible)]="displayDialog"
  [style]="{'min-width':'70wh'}"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <div class="" [style.max-height]="'calc(80vh)'" style=" overflow-y:auto;overflow-x: hidden">
    <dx-data-grid
      [dataSource]="plcList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(300)"
      [width]="utils.getWindowAvailableWidth(300)"
      (onSelectionChanged)="onChangeSelection($event)"
      [(selectedRowKeys)]="selectedRows"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      #gridPlc>

      <dxi-column dataField="client.site.libelle" caption="Site" [minWidth]="150"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="client.libelle" caption="Client" [minWidth]="150"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="libelle" caption="Point de livraison" [minWidth]="200"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="code" caption="Code"
                  [minWidth]="200"
                  [allowFiltering]="true">
      </dxi-column>

      <dxo-filter-row
        [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection [mode]="'multiple'"></dxo-selection>
      <dxo-paging [pageSize]="50"></dxo-paging>

      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [showNavigationButtons]="false"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} items">
      </dxo-pager>


    </dx-data-grid>
  </div>
  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
    </div>
    <div>
      <span class="mg-r-5">
        <yo-button iconClass="fas fa-plus" (onClick)="addPlc()"
                   [disabled]="selectedPlcList.length === 0" tooltip="Ajouter" buttonClass="cta-success">
        </yo-button>
      </span>
      <span class="mg-r-5">
        <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
                   buttonClass="cta-delete">
        </yo-button>
      </span>
    </div>
  </div>
</dx-popup>

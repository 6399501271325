import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {Observable, Subscription} from 'rxjs';
import {UtilsService} from '../../../../core/utils/utils.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {SecteurFournisseurDTO} from '../../../../core/dtos/secteurfournisseur-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {SecteursFournisseursService} from '../../../../core/services/entities/secteurs-fournisseurs.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {ObjectDTO} from '../../../../core/dtos/object-dto';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {TagBoxComponent} from "../../../../shared/ui/tag-box/tag-box.component";
import {SiteService} from "../../../../core/site.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'yo-filiale-form-dialog',
  templateUrl: './filiale-form-dialog.component.html',
  styleUrls: ['./filiale-form-dialog.component.scss'],
})
export class FilialeFormDialogComponent implements OnInit, OnDestroy {

  @ViewChild("siteLookup") siteLookup: LookUpComponent;
  @ViewChild("secteursTagbox") secteursTagBox: TagBoxComponent;
  @ViewChild("filialeForm") filialeForm: NgForm;

  @Input() displayDialog: boolean;
  @Output() onClose = new EventEmitter<boolean>();

  sitesList: SiteDTO[];
  selectedSite: number;
  filiale: FournisseurDTO;
  secteurList: SecteurDTO[] = [];
  editSelectedSecteurs: number[];

  private subSites: Subscription;
  private subSecteurs: Subscription;
  subEditFiliale: Subscription;
  subCreateFiliale: Subscription;

  isCreateMode: boolean;

  title: string = '';

  constructor(public gds: GenericDatagridService,
              public auth2Svc: Auth2Service,
              private fournisseurSvc: FournisseurService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private utils: UtilsService,
              private gfs: GenericFormService,
              private secteursFournisseursService: SecteursFournisseursService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService,
              private sitesSvc: SiteService) {
  }


  ngOnInit(): void {
    this.initSecteurs();
    this.initSites();
    this.initEdit();
    this.initCreate();
  }


  private initSites() {
    this.subSites = this.sitesSvc.getListeSitesActifs().subscribe((sites) => {
      if (sites)
        this.sitesList = sites;
      else
        this.sitesList = [];
    });
  };

  initSecteurs = (): void => {
    this.subSecteurs = this.getSecteurs().subscribe(response => {
      if (!this.utils.isResponseSupplierError(response))
        this.secteurList = response.allSecteurs;
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSites);
    this.utils.unsubscribe(this.subSecteurs);
    this.utils.unsubscribe(this.subEditFiliale);
    this.utils.unsubscribe(this.subCreateFiliale);
  }

  getSecteurs = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allSecteurs(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            site {
                id,
                libelle,
            },
        }
      }
    `);
  }

  initEdit = () => {
    this.subEditFiliale = this.fournisseurSvc.editFiliale$.subscribe((data: FournisseurDTO) => {
      this.isCreateMode = false;

      this.gestionFournisseurSvc.getFournisseuById(this.utils.isNullOrEmpty(data) ? 0 : data.id).subscribe((response: ResponseWrapper<FournisseurDTO>) => {
        this.filiale = response.one;
        this.title = 'Edition de la Filiale ' + this.filiale.libelle;
        this.selectedSite = this.filiale.site.id;
        this.initSelectedSecteurs();
      });
    });

  };

  initCreate = () => {
    this.subCreateFiliale = this.fournisseurSvc.createFiliale$.subscribe((data: FournisseurDTO) => {
      this.editSelectedSecteurs = [];
      this.selectedSite = 0;
      this.isCreateMode = true;
      this.title = 'Création d\'une Filiale ';
      this.filiale = new FournisseurDTO();
      this.filiale.adresse = new AdresseDTO(data.site);
      this.filiale.actif = true;
      this.filiale.francoDePortActif = false;
      let parent = new ObjectDTO();

      //parent
      this.filiale.parent = parent;
      this.filiale.parent.id = data.id;
      this.filiale.parent.code = data.code;
      this.filiale.parent.libelle = data.libelle;
      this.filiale.parent.actif = data.actif;

      //filiale
      this.filiale.site = data.site;
      this.filiale.libelle = data.libelle;
      this.filiale.id = 0;
      this.filiale.francoDePortTva = 20;
      this.filiale.francoDePortCout = 0;
    });
  };

  closeDialog = (isFromSavingBtn: boolean) => {
    this.displayDialog = false;
    this.onClose.emit(isFromSavingBtn);
  };

  isVisibleSaveBtn = () => {
    if (this.isCreateMode) {
      return true;
    } else {
      return this.auth2Svc.isSiteLocal(this.filiale?.site.id);
    }
  };

  initSaveFiliale = () => {

    //On enregistre que si le formulaire est valide

    //Filiale
    this.filiale.site = {id: this.siteLookup.getSelectedItem().id} as SiteDTO;
    this.filiale.idFournisseur = null;

    // cout du franco de port, si null on le force à 0
    this.filiale.francoDePortCout = this.filiale.francoDePortCout ? this.filiale.francoDePortCout : 0;
    this.filiale.francoDePortTva = this.filiale.francoDePortTva ? this.filiale.francoDePortTva : 20;

    //Adresse
    this.filiale.adresse.entityName = this.fournisseurSvc.getEntityName();

    //Secteurs de livraison
    this.filiale.secteurFournisseurList = [];
    let selectedSecteurs: SecteurDTO[] = this.secteursTagBox.getSelectedItems();

    if (this.utils.isCollectionNullOrEmpty(selectedSecteurs))
      selectedSecteurs = [];

    selectedSecteurs.map(secteur => {
      let secteurFournisseur = new SecteurFournisseurDTO(this.filiale.id, secteur);
      this.filiale.secteurFournisseurList.push(secteurFournisseur);
    });
    //Persistence en back
    this.saveFiliale();
  }

  saveFiliale = () => {
    this.utils.announceSubjectBlockui(true);
    this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Cette opération peut prendre du temps`);

    this.gestionFournisseurSvc.saveFournisseur(this.filiale).subscribe(response => {
      this.utils.announceSubjectBlockui(false);
      if (!this.utils.isResponseSupplierError(response)) {
        this.secteursFournisseursService.getAllFromEnvironnement();
        //annoncer que la fiche d'identite a été sauvegardée. La grille des fournisseurs doit être abonnée pour faire la mise à jour
        this.filiale = response.one;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La filiale ${this.filiale.libelle} a bien été enregistrée`);
        this.gestionFournisseurSvc.announceRefreshGrid(this.filiale);
        this.closeDialog(true);
      }
    });
  };

  private initSelectedSecteurs() {
    this.editSelectedSecteurs = this.filiale.secteurFournisseurList.map(secteur => secteur.secteur.id);
  }

  isFormValid = (): boolean => {
    return this.siteLookup?.getSelectedItem() && this.secteursTagBox?.getSelectedItems().length !== 0 && this.filialeForm?.valid;
  }
}

// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-9953(0fd28fa0a4)-C26/02/2024-11:30:13-B26/02/2024-11:34:53' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-9953(0fd28fa0a4)-C26/02/2024-11:30:13-B26/02/2024-11:34:53",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "9953",
  shortHash: "0fd28fa0a4",
  longHash: "0fd28fa0a4aab4f53be1df206b93e8312c4e17dd",
  dateCommit: "26/02/2024-11:30:13",
  dateBuild: "26/02/2024-11:34:53",
  buildType: "Ansible",
  } ;

<form *ngIf="groupeAchat" (ngSubmit)="saveFicheIdentite()" #groupeAchatForm="ngForm">
  <div class="dialog-line">
    <div class="custom-button-container-right save-container">
      <yo-button iconClass="fas fa-save"
                 (onClick)="saveFicheIdentite()"
                 tooltip="Enregistrer"
                 buttonClass="cta-success">

      </yo-button>
    </div>
  </div>
  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >
    <div class="dialog-line">
      <div class="label" title="site">Site <span class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          #siteLookup
          [data]="sitesList"
          [selectedItem]="selectedSite"
        >
        </yo-lookup>
      </div>
    </div>

    <div class="dialog-line" *ngIf="groupeAchat.id !== 0">
      <div class="label" title="Code">Code </div>
      <div class="value">
        <dx-text-box
          class='inputfield'
          [(ngModel)]="groupeAchat.code"
          #code="ngModel"
          name="code"
          readOnly="true"
        >
        </dx-text-box>
      </div>
    </div>


    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box
          required
          class='inputfield'
          [(ngModel)]="groupeAchat.libelle"
          #libelle="ngModel"
          name="libelle"
        >
        </dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Description">Description</div>
      <div class="value">
        <dx-text-area
          class='inputfield'
          [(ngModel)]="groupeAchat.descriptif"
          #description="ngModel"
          name="description"
        >
        </dx-text-area>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Actif">Actif</div>
      <div class="value">
        <dx-check-box
          [(ngModel)]="groupeAchat.actif"
          #actif="ngModel"
          name="actif"
        >
        </dx-check-box>
      </div>
    </div>


  </dx-scroll-view>
</form>

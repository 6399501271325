import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {EnvironnementResolverService} from '../../environnement-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {EnvironnementDTO} from '../../../core/dtos/environnement-dto';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {SiteService} from '../../../core/site.service';
import {EnvironnementService} from '../../../core/services/entities/gestionenvironnements/environnement.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {BaseComponent} from '../../../base-component';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ToastService} from "../../../core/services/technique/toast.service";
import {TagBoxComponent} from "../../../shared/ui/tag-box/tag-box.component";
import {EnvironnementSitesVisiblesDTO} from "../../../core/dtos/enrironnement-sites-visibles-dto";

@Component({
  selector: 'yo-ficheidentite-environnement',
  templateUrl: './ficheidentite-environnement.component.html',
  styleUrls: ['./ficheidentite-environnement.component.scss'],
})
export class FicheidentiteEnvironnementComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild("sitesReferents") sitesReferentsTagBox: TagBoxComponent;
  @ViewChild("sitesLocaux") sitesLocauxTagBox: TagBoxComponent;

  isPopupDisplayed: boolean = false;

  title: string = 'Création d\'un environnement';

  listSites: SiteDTO[] = [];

  private subAllSites: Subscription;
  private subOpenDialog: Subscription;
  private subFicheIdentite: Subscription;
  private subDeleteEnvironnement: Subscription;
  private subEnvironnement: Subscription;
  formGroupCtrl: FormGroup;

  selectedSitesLocaux: number[] = [];
  selectedSitesReferents: number[] = [];

  environnement: EnvironnementDTO = new EnvironnementDTO();

  sitePrincipal: SiteDTO = new SiteDTO();

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private siteSvc: SiteService,
              private environnementSvc: EnvironnementService,
              private environnementResolverSvc: EnvironnementResolverService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private toastSvc: ToastService) {
    super();
  }

  ngOnInit() {
    this.initSites();
    this.initForm();
    this.initOpenDialogSubscription();
  }

  private initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.environnementSvc.openDialog$.subscribe(environnement => {
      this.isPopupDisplayed = true;
      if (!environnement) {
        this.environnement = new EnvironnementDTO();
        this.environnement.id = 0;
        this.title = 'Création d\'un environnement';
      } else {
        this.environnement = environnement;
        this.title = 'Modification d\'un environnement';
      }
      this.initForm()
    })
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFicheIdentite);
    this.utils.unsubscribe(this.subDeleteEnvironnement);
    this.utils.unsubscribe(this.subEnvironnement);
    this.utils.unsubscribe(this.subAllSites);
    this.utils.unsubscribe(this.subOpenDialog);
  }

  closeDialog = (): void => {
    this.isPopupDisplayed = false;
  };

  initForm = (): void => {
    this.selectedSitesLocaux = this.getMatchingSite(true);
    this.selectedSitesReferents = this.getMatchingSite(false);
    this.formGroupCtrl = new FormGroup({
      libelle: new FormControl(this.environnement?.libelle),
      code: new FormControl(this.environnement?.code),
    });
  }

  private getMatchingSite = (isLocal: boolean) => {
    return this.environnement?.environnementSitesVisiblesDTOList
      .filter(site => site.local === isLocal)
      .map(env => env.site.id);
  }

  private initSites = (): void => {
    this.subAllSites = this.siteSvc.getListeSitesActifs().subscribe(response => this.listSites = response);
  }

  save = (): void => {
    this.environnement.libelle = this.formGroupCtrl.controls["libelle"].value
    this.environnement.code = this.formGroupCtrl.controls["code"].value
    this.updateOrAddSitesReferents(this.sitesLocauxTagBox.getSelectedItems(), true);
    this.updateOrAddSitesReferents(this.sitesReferentsTagBox.getSelectedItems(), false);

    this.environnementSvc.save(this.environnement).subscribe(data => {
        let result: ResponseWrapper<boolean> = data;
        if (result.inError) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${result.resultMessage}`);
        } else {
          this.refreshEnvironmentWhenUserHasSameEnvironment();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'environnement ${this.environnement.libelle} a été enregistré avec succès`);

          this.formGroupCtrl.markAsPristine();
          this.environnementResolverSvc.environnementAnnounceSource.next(this.environnement);

          this.closeDialog();
        }
      });
  };

  private updateOrAddSitesReferents = (sites: SiteDTO[], isLocal: boolean): void => {
    console.log(sites)
    console.log(this.environnement)
    sites.forEach(site => {
      const index = this.environnement.environnementSitesVisiblesDTOList.findIndex(environnementSite => environnementSite.site.id == site.id);

      if (index >= 0)
        this.environnement.environnementSitesVisiblesDTOList[index].local = isLocal;
      else
        this.environnement.environnementSitesVisiblesDTOList.push(this.generateEnvironnementSite(site, isLocal));
    });
  };

  private generateEnvironnementSite = (site: SiteDTO, isLocal: boolean): EnvironnementSitesVisiblesDTO => {
    const environnementSite: EnvironnementSitesVisiblesDTO = new EnvironnementSitesVisiblesDTO();

    environnementSite.id = 0;
    environnementSite.ordre = this.getNextOrdre();
    environnementSite.site = site;
    environnementSite.environnementDTO = this.getDuplicateEnvironnement();
    environnementSite.local = isLocal;

    return environnementSite;
  }

  private getDuplicateEnvironnement = () => {
    const duplicate = new EnvironnementDTO();
    duplicate.id = this.environnement.id;
    duplicate.libelle = this.environnement.libelle;

    return duplicate;
  }

  private getNextOrdre = (): number => {
    if (this.environnement.environnementSitesVisiblesDTOList.length === 0)
      return 1;
    return this.environnement.environnementSitesVisiblesDTOList.reduce((env1, env2) => env1.ordre > env2.ordre ? env1 : env2).ordre + 1;
  }

  refreshEnvironmentWhenUserHasSameEnvironment = (): void => {
    if (this.auth2Svc.utilisateur.iddefaultenvironnement === this.environnement.id)
      this.auth2Svc.changementEnvironnement(this.environnement);
  };

  onSiteReferentChange = (data: number[]) => {
    this.selectedSitesReferents = data;
    this.environnement.environnementSitesVisiblesDTOList =
      this.environnement.environnementSitesVisiblesDTOList.filter(esv => esv.local == false && this.selectedSitesReferents.includes(esv.site.id));
    let newSites = this.selectedSitesLocaux.filter(site => !data.includes(site))
    if (this.selectedSitesLocaux.length != newSites.length)
      this.selectedSitesLocaux = newSites;
  };

  onSiteLocauxChange = (data: number[]) => {
    this.selectedSitesLocaux = data;
    this.environnement.environnementSitesVisiblesDTOList =
      this.environnement.environnementSitesVisiblesDTOList.filter(esv => esv.local == true && this.selectedSitesLocaux.includes(esv.site.id));
    let newSites = this.selectedSitesReferents.filter(site => !data.includes(site))
    if (this.selectedSitesReferents.length != newSites.length)
      this.selectedSitesReferents = newSites;
  };
}

import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GroupeAchatService, TAB_NAME_GR} from "../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {GroupeAchatDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-dto";
import {Subscription} from "rxjs";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {SiteService} from "../../../../core/site.service";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";

@Component({
  selector: 'yo-dialog-groupe-achat',
  templateUrl: './fiche-identite-groupe-achat.component.html',
  styleUrls: ['./fiche-identite-groupe-achat.component.scss']
})
export class FicheIdentiteGroupeAchatComponent implements OnInit, OnDestroy {

  @ViewChild('siteLookup') siteLookup: LookUpComponent;

  private subSites: Subscription;

  groupeAchat: GroupeAchatDTO;
  sitesList: SiteDTO[];
  selectedSite: number;


  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-edition-groupe-achat';


  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private toastSvc: ToastService,
              private sitesSvc: SiteService,
              @Inject('dynamicData') public groupeAchatData: GroupeAchatDTO) {
  }

  ngOnInit() {
    this.initSites();
    this.initGroupeAchat();
  }

  private initGroupeAchat = ():void => {
    if (this.groupeAchatData) {
      this.groupeAchat = this.groupeAchatData;
      if (this.groupeAchatData.id !== 0)
        this.selectedSite = this.groupeAchatData.site.id
      else
        this.groupeAchat.actif = true;
    }
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subSites);
  }

  saveFicheIdentite = ():void => {

    const groupeAchatClean = this.getCleanGroupeAchat();
    groupeAchatClean.site = this.utils.getLightSite(this.siteLookup.getSelectedItem());

    this.groupeAchatSvc.saveGroupeAchat(groupeAchatClean)
      .subscribe(
        (response: ResponseWrapper<GroupeAchatDTO>) => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du groupe achat ${response.one.libelle} réalisé avec succès`);
          this.groupeAchatSvc.announceGroupeAchat(response.one, TAB_NAME_GR.info);
        }
      );
  };

  private getCleanGroupeAchat = () => JSON.parse(JSON.stringify(this.groupeAchat));

  help() {
  }

  private initSites = (): void => {
    this.subSites = this.sitesSvc.getListeSitesActifs().subscribe((sites) => {
      if (sites)
        this.sitesList = sites;
      else
        this.sitesList = [];
    });
  };

}

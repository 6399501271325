import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GroupeAchat__FournisseurDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-fournisseur-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GroupeAchatService} from "../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {Subscription} from "rxjs";
import {GroupeAchatDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-dto";
import {HELP_FOLDERS, MSG_KEY} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../core/models/is-deletable-object";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-founisseur-groupe-achat',
  templateUrl: './founisseur-groupe-achat.component.html',
  styleUrls: ['./founisseur-groupe-achat.component.scss']
})
export class FounisseurGroupeAchatComponent implements OnInit, OnDestroy {

  groupeAchatFournisseurList: GroupeAchat__FournisseurDTO[] = [];
  groupeAchat: GroupeAchatDTO;
  selectedFournisseurs: number[] = [];

  private subFournisseurs: Subscription;

  @ViewChild('grid') grid: DxDataGridComponent;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-groupe-achat';

  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private auth2Svc: Auth2Service,
              @Inject('dynamicData') public groupeAchatData: GroupeAchatDTO) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subFournisseurs)
  }

  initData = () => {
    if (this.groupeAchatData) {
      this.groupeAchat = this.groupeAchatData;
      this.loadFournisseurGroupeAchat();
    }
  };


  loadFournisseurGroupeAchat = () => {
    this.groupeAchatSvc.getGroupeAchatFournisseurList(this.groupeAchat.id)
      .subscribe((data: ResponseWrapper<GroupeAchat__FournisseurDTO>) => {
        this.groupeAchatFournisseurList = data.resultList;
      });
  };

  openAddFournisseur = () => {
    this.groupeAchatSvc.announceOpenDialogAddFournisseur();
  };

  canAdd = () => this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);

  canModify = () => this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);

  help = () => {
  };

  removeFournisseurs = async (selectedFournisseurs: number[]) => {
    const result = confirm(`Etes vous sûr(e) de vouloir supprimer le(s) ${selectedFournisseurs.length} fournisseur(s) sélectionné(s) ?`,
      'Suppression de fournisseur');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.groupeAchatSvc.deleteGroupeAchatFournisseur(selectedFournisseurs)
        .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
          this.loadFournisseurGroupeAchat();
        });
    }
  };
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import ConditionnementVarianteDTO from "../../../../core/dtos/conditionnement/conditionnement-variante-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {ConditionnementsVarianteService} from "../../../../core/services/conditionnements/conditionnement-variante.service";
import {ConditionnementsService} from "../../../../core/services/conditionnements/conditionnements.service";
import {VariantesService} from "../../../../core/services/conditionnements/variantes.service";
import ConditionnementDTO from "../../../../core/dtos/conditionnement/conditionnement-dto";
import VarianteDTO from "../../../../core/dtos/conditionnement/variante-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {TagBoxComponent} from "../../../../shared/ui/tag-box/tag-box.component";

@Component({
  selector: 'yo-conditionnement-variante',
  templateUrl: './conditionnement-variante.component.html',
  styleUrls: ['./conditionnement-variante.component.scss']
})
export class ConditionnementVarianteComponent implements OnInit, OnDestroy {

  @ViewChild("site") siteLookup: LookUpComponent;
  @ViewChild("conditionnement") conditionnementLookup: LookUpComponent;
  @ViewChild("variante") variantesList: TagBoxComponent;

  hasIDistri = false;

  displayDialog = false;

  sitePlaceholder: string = 'Selectionner le site...';

  subOpenDialogconditionnementVariante: Subscription;
  subAllConditionnements: Subscription;
  subAllVariantes: Subscription;

  form: FormGroup = new FormGroup({});

  conditionnements: ConditionnementDTO[] = [];

  variantes: VarianteDTO[] = [];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private conditionnementVariantesSvc: ConditionnementsVarianteService,
              private conditionnementSvc: ConditionnementsService,
              private varianteSvc: VariantesService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initConditionnements();
    this.initVariantes();
    this.initForm();
    this.initHasGestionIDistri();
    this.openDialogconditionnementVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogconditionnementVariante);
    this.utils.unsubscribe(this.subAllConditionnements);
    this.utils.unsubscribe(this.subAllVariantes);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  initConditionnements = (): void => {
    this.subAllConditionnements = this.conditionnementSvc.getAll()
      .subscribe(response => this.conditionnements = response.resultList);
  };

  initVariantes = (): void => {
    this.subAllVariantes = this.varianteSvc.getAll()
      .subscribe(response => this.variantes = response.resultList);
  };


  openDialogconditionnementVarianteSubscription = (): void => {
    this.subOpenDialogconditionnementVariante = this.conditionnementVariantesSvc.openDialogConditionnementVariante$
      .subscribe((conditionnementVariante: ConditionnementVarianteDTO) => {
        this.displayDialog = true;
        this.initForm();
      });
  };

  initConditionnementVariante = (site: SiteDTO): ConditionnementVarianteDTO => {
    const cv: ConditionnementVarianteDTO = new ConditionnementVarianteDTO();
    cv.id = 0;
    cv.site = site;
    cv.variante = new VarianteDTO();
    cv.conditionnement = new ConditionnementDTO();
    return cv;
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canSave = (): boolean => this.hasIDistri;

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    const site: SiteDTO = {id: this.siteLookup.getSelectedItem().id} as SiteDTO;
    const conditionnement: ConditionnementDTO = this.conditionnementLookup.getSelectedItem();
    const variantes :VarianteDTO[] = this.variantesList.getSelectedItems();

    this.form.controls["site"].setValue(site);
    this.form.controls["conditionnement"].setValue(conditionnement);
    this.form.controls["variante"].setValue(variantes);

    if (this.form.valid) {
      const conditionnementVarianteList = variantes.map(variante => {
        const cv: ConditionnementVarianteDTO = this.initConditionnementVariante(site);
        cv.conditionnement = conditionnement;
        cv.variante = variante;
        return cv;
      });

      this.conditionnementVariantesSvc.save(conditionnementVarianteList)
        .subscribe(response => {
          this.conditionnementVariantesSvc.announceConditionnementVarianteSaved(response.resultList, false);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Création de l'association réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {

    this.form = new FormGroup({
      conditionnement: new FormControl(null, [Validators.required]),
      variante: new FormControl(null, [Validators.required]),
      site: new FormControl(null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Association conditionnement variante`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService,
  PROVENANCE
} from '../../../../core/services/entities/catalogues-achats.service';
import {Subscription} from 'rxjs';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {cloneDeep as _cloneDeep} from 'lodash';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {Router} from '@angular/router';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {ProduitDTO} from "../../../../core/dtos/produit-dto";
import {UnitesDeMesuresService} from "../../../../core/services/entities/unites-de-mesures.service";
import {UniteDeMesureDTO} from "../../../../core/dtos/unitedemesure-dto";
import {ProduitDeclinaisonDTO} from "../../../../core/dtos/produit-declinaison-dto";
import {NgForm} from "@angular/forms";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {AllergeneDTO} from "../../../../core/dtos/allergene-dto";
import {AllergenesService} from "../../../../core/services/entities/allergenes-service.service";
import {AppellationsService} from "../../../../core/services/entities/appellations.service";
import {AppellationDTO} from "../../../../core/dtos/appellations-dto";
import {GestionFournisseurService} from "../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {DxTagBoxComponent} from "devextreme-angular";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  @ViewChild('articleForm') articleForm: NgForm;
  @ViewChild('produitDeclinaison') produitDeclinaison: LookUpComponent;
  @ViewChild('uniteDeFacturation') uniteDeFacturation: LookUpComponent;
  @ViewChild('uniteDeCommande') uniteDeCommande: LookUpComponent;
  @ViewChild('uniteStockage') uniteStockage: LookUpComponent;
  @ViewChild('appellationsTagBox') appellationsTagBox: DxTagBoxComponent;

  unitesDeMesure: any[] = [];
  title: string;
  uniteDeProduction: UniteDeProductionDTO;
  produit: ProduitDTO;
  declinaisonsList: ProduitDeclinaisonDTO[];
  displayDialog = false;
  catalogueAchat: CatalogueAchatDTO;
  fullScreen: boolean = false;
  cads: CatalogueAchatDialogSupplier;

  private subFournisseurs: Subscription;
  private subUnitesDeProduction: Subscription;

  subOpenDialog: Subscription;
  private subAllergenes: Subscription;
  private subAppelations: Subscription;
  private subArticleAppellations: Subscription;

  allergeneList: AllergeneDTO[] = [];
  produitAllergenes: number[] = [];
  allAppellations: AppellationDTO[];
  appellationsProduit: number[] = [];
  fournisseursList: any[];


  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private catalogueAchatSvc: CataloguesAchatsService,
              private gfs: GenericFormService,
              private gds: GenericDatagridService,
              private router: Router,
              private routeMapSvc: RoutemapService,
              public domSanitizer: DomSanitizer,
              private unitesDeMesuresSvc: UnitesDeMesuresService,
              private allergenesSvc: AllergenesService,
              private appelationsSvc: AppellationsService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService) {
  }

  ngOnInit(): void {
    this.initAllergenes();
    this.initAppellations();
    this.initUnitesDeMesure();
    this.openDialogSubscription();
  }

  openDialogSubscription = () => {
    this.subOpenDialog = this.catalogueAchatSvc.openDialogArticle$
      .pipe(
        tap(data => this.cads = data),
        tap(data => {
          this.displayDialog = true;
          switch (data.routeProvenance) {
            case PROVENANCE.FOURNISSEUR:
              this.router.navigate(data.rootRoute);
              break;
            case PROVENANCE.PRODUIT:
              this.routeMapSvc.goToSecondaryRoute(data.rootRoute);
              break;
          }
        }),
        tap((data) => {
          if (data) {
            this.produit = _cloneDeep(data.produit);
            this.declinaisonsList = this.produit.produitDeclinaisons;
            this.uniteDeProduction = _cloneDeep(data.uniteDeProduction);
            if (data.catalogueAchat)
              this.catalogueAchat = data.catalogueAchat;
            else {
              this.catalogueAchat = this.catalogueAchatSvc.createEmptyCatalogueAchatDTO(this.produit);
              this.catalogueAchat.produitCarne = false;
              this.catalogueAchat.site = this.uniteDeProduction.site;
            }
          }
          this.initTitle();
          this.initFournisseurs();
        }))
      .subscribe()
  };

  initAppellations = (): void => {
    this.subAppelations = this.catalogueAchatSvc.openDialogArticle$.pipe(
      switchMap(data => {
        const idsSites = this.auth2Svc.utilisateur.sites.map(s => s.id);
        return this.graphQlSvc.sendQuery(`
          {
            allAppellations(filters: {
              siteIds: [${idsSites}],
              actif: true
            }) {
                id,
                code,
                libelle,
                actif,
                durable,
                bio,
                site {
                    id,
                    libelle,
                },
            }
          }
        `);
      }),
      switchMap(data => {
        this.allAppellations = data.allAppellations;
        return this.catalogueAchatSvc.getProduitArticleAppellations(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(data => {
        this.appellationsProduit = data.resultList.map(appellations => appellations.appellationId);
      });
  };


  initAllergenes = (): void => {
    this.subAllergenes = this.catalogueAchatSvc.openDialogArticle$.pipe(
      switchMap(data => {
        const idsSites = this.auth2Svc.utilisateur.sites.map(s => s.id);
        return this.graphQlSvc.sendQuery(`
          {
            allAllergenes(filters: {
                siteIds: [${idsSites}]
              }) {
              id,
              libelle,
              appellationInformationConsommateur,
              code,
              actif,
              site { id }
            }
          }
        `);
      }),
      switchMap(data => {
        this.allergeneList = data.allAllergenes;
        return this.catalogueAchatSvc.getProduitArticleAllergenes(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(data => {
        this.produitAllergenes = data.resultList.map(allergenes => allergenes.allergeneId);
      });

  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFournisseurs);
    this.utils.unsubscribe(this.subUnitesDeProduction);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subArticleAppellations);
  }

  initTitle = (): void => {
    if (this.catalogueAchat.id === 0) {
      this.title = 'Créer un article';
    } else {
      this.title = `Modifier l'article ${this.catalogueAchat.produitArticle.libelle.toUpperCase()} /  ${this.catalogueAchat.fournisseur.libelle}`;
    }
  };

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  private initUnitesDeMesure = ():void => {
    this.subUnitesDeProduction = this.unitesDeMesuresSvc.getAllUniteDeMesure().subscribe((data: UniteDeMesureDTO[]) => {
      if (data) {
        this.unitesDeMesure = data['resultList'];
      } else
        this.unitesDeMesure = [];
    });
  };

  isFormValid = (): boolean => {
    return this.articleForm?.valid &&
      this.produitDeclinaison?.getSelectedItem() &&
      this.uniteDeFacturation?.getSelectedItem() &&
      this.uniteDeCommande?.getSelectedItem() &&
      this.uniteStockage?.getSelectedItem() &&
      this.catalogueAchat?.fournisseur !== undefined;
  }

  onChange = (id: number, propriete: string): void => {
    this.catalogueAchat[propriete] = this.unitesDeMesure.find(unite => unite.id == id)
  }


  private initFournisseurs = ():void => {
    if (this.catalogueAchat.id == 0) {
      this.subFournisseurs = this.gestionFournisseurSvc.getFournisseurListByIdUdpAndIdProduitDeclinaison(this.uniteDeProduction.id, this.catalogueAchat.produitArticle.produitDeclinaison.id).subscribe((data) => {
        if (data) {
          this.fournisseursList = data.resultList;
        }
      })
    } else {
      this.fournisseursList = []
      this.fournisseursList.push(this.catalogueAchat.fournisseur);
    }
  };

  saveCatalogueAchat = (): void => {

    const prix = parseFloat((this.catalogueAchat.prix + '').trim().replace(',', '.'));
    this.catalogueAchat.prix = prix;
    this.catalogueAchat.prixOrigine = this.catalogueAchat.prix;

    this.catalogueAchatSvc.saveCatalogueAchat(this.catalogueAchat, false).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {

        this.catalogueAchat = response.one;
        this.cads.catalogueAchat = this.catalogueAchat;
        this.cads.isNew = false;

        // on notifie les abonnés que l'article a été sauvegardé
        this.catalogueAchatSvc.announceSavedDTO(response);
        this.catalogueAchatSvc.announceCloseDialogArticle(null);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement de l'article "${this.catalogueAchat.produitArticle.libelle.toUpperCase()}" effectué. Vous pouvez (si vous le voulez) renseigner les appellations et les allergènes.`);
      }
    });
    if (this.catalogueAchat.id !== 0) {
      this.saveAllergenes();
      this.saveAppellations();
    }
    this.displayDialog = false;
  }

  onChangeSelectedFournisseur = ($event) => {
    this.catalogueAchat.fournisseur = $event.currentSelectedRowKeys[0]
  }

  saveAllergenes = () => {
    if (this.produitAllergenes.length !== 0) {
      const allergenes: AllergeneDTO[] = this.allergeneList.filter((allergene) => this.produitAllergenes.includes(allergene.id));
      this.catalogueAchatSvc.saveProduitArticleAllergenes(this.catalogueAchat.produitArticle.id, allergenes).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response))
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les allergènes ont été enregistrés avec succès`);
      });
    }
  };

  saveAppellations = () => {
    if (this.appellationsProduit.length !== 0) {
      const appellations: AppellationDTO[] = this.allAppellations.filter((appellation) => this.appellationsProduit.includes(appellation.id));
      this.catalogueAchatSvc.saveProduitArticleAppellations(this.catalogueAchat.produitArticle.id, appellations).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les appellations ont été enregistrées avec succès`);
      });
    }
  };

  onAllergenesChange = ($event): void => {
    this.produitAllergenes = $event.value;
  };

  onAppellationsChange = ($event): void => {
    this.appellationsProduit = $event.value;
  };
}

<dx-tag-box
  #tagbox
  [items]="data"
  [value]="selectedItems"
  [displayExpr]="displayValue"
  [valueExpr]="key"
  [searchEnabled]="enableSearch"
  [searchExpr]="displayValue"
  searchMode="contains"
  applyValueMode="useButtons"
  [maxDisplayedTags]="3"
  [showMultiTagOnly]="false"
  [multiline]="false"
  [placeholder]="placeholder"
  [showSelectionControls]="true"
  (onValueChanged)="valueChanged($event)"
>
</dx-tag-box>

<dx-popup
  [showTitle]="true"
  [title]="'Impression des menus'"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="dx-field">
    <div class="dx-field-label">Du :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de début"
        type="date"
        [value]="startDate"
        (onValueChanged)="startDateChanged($event)">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">au :</div>
    <div class="dx-field-value">
      <dx-date-box
        title="Selectionner la date de fin"
        type="date"
        [value]="endDate"
        (onValueChanged)="endDateChanged($event)"
        [min]="minDate">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Régimes :</div>
    <div class="dx-field-value">
      <dx-select-box
        placeholder="Choisir un régime"
        displayExpr="label"
        valueExpr="value"
        [items]="regimeOptionList"
        [value]="selectedRegimeId"
        (onValueChanged)="regimeChanged($event)"
      ></dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Jours :</div>
    <div class="dx-field-value">
      <dx-drop-down-box
        [dataSource]="dayOfWeekOptionList"
        [(value)]="treeBoxValue"
        valueExpr="value"
        displayExpr="viewValue"
        placeholder="Sélectionner..."
        [showClearButton]="true"
        (onValueChanged)="onDropDownBoxValueChanged()"
        (onInitialized)="initDayOfWeekOptionListWithUserPerference()"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            [dataSource]="dayOfWeekOptionList"
            [height]="150"
            dataStructure="plain"
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            [selectNodesRecursive]="false"
            keyExpr="value"
            displayExpr="viewValue"
            [selectByClick]="true"
            (onContentReady)="onTreeViewReady($event)"
            (onItemSelectionChanged)="onTreeViewSelectionChanged($event)"
            [searchEnabled]="true"
            searchMode="contains"
          >
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Message personnalisé :</div>
    <div class="dx-field-value">
      <dx-text-area [(ngModel)]="messagePerso"></dx-text-area>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">
      <p-fileUpload name="demo[]"
                    customUpload="true"
                    (uploadHandler)="uploadMenuLogo($event)"
                    accept="image/*"
                    [auto]="true"
                    mode="basic"
                    [disabled]="!isLogo"
                    chooseLabel="Image logo"
                    maxFileSize="1000000">
      </p-fileUpload>
    </div>
    <div class="dx-field-value">
      <div *ngIf="isLogo" class="image-logo-menu">
        <img src="dolrest/utils/parametre-image?imageName=menuLogo&date={{refresh}}" height="32" alt="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isContratPrestationRegime"
          text="Offre, Prestation, Régime"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isPrice"
        text="Coût matière plat"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isLibelleLong"
          text="Plat libellé long"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isCoutRepas"
        text="Coût matière repas"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <dx-check-box
          [(ngModel)]="isCouleur"
          text="Code couleur des aliments"
          [elementAttr]="{ 'aria-label': 'Checked' }"
        ></dx-check-box>
    </div>
    <div class="col-lg-6">
      <dx-check-box
        [(ngModel)]="isLogo"
        text="Logo"
        [elementAttr]="{ 'aria-label': 'Checked' }"
      ></dx-check-box>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button label="Imprimer" [disabled]="this.isValidForm()" iconClass="fas fa-print"
               (onClick)="downloadMenu()">
    </yo-button>

    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (onClick)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  [showCloseButton]="true"
  width="70vw"
  height="70vh"
  (onHidden)="closeDialog()"
  [fullScreen]="fullScreen"
  [resizeEnabled]="!fullScreen"
  [dragEnabled]="!fullScreen"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>


  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >

    <form *ngIf="catalogueAchat" (ngSubmit)="saveCatalogueAchat()" #articleForm="ngForm">
      <div class="title-ca">Article</div>
      <div class="container-catalogArticle">
        <div *ngIf="catalogueAchat.id !== 0" class="dialog-line">
          <div class="label" title="Code API">Code API</div>
          <div class="value">
            <dx-text-box
              readOnly="true"
              class='inputfield'
              [(ngModel)]="catalogueAchat.codeApi"
              #code="ngModel"
              name="code"
            >
            </dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.produitArticle.libelle"
              #libelle="ngModel"
              name="libelle"
            >
            </dx-text-box>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Produit déclinaison">Produit déclinaison <span class="danger-color">*</span></div>
          <div class="value">
            <yo-lookup
              #produitDeclinaison
              [data]="declinaisonsList"
              [selectedItem]="catalogueAchat.produitArticle.produitDeclinaison.id"
            >
            </yo-lookup>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Référence article fournisseur">Réf. art. fournisseur <span
            class="danger-color">*</span>
          </div>
          <div class="value">
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.referenceArticle"
              #referenceArticle="ngModel"
              name="referenceArticle"
            >
            </dx-text-box>
          </div>
        </div>

        <div class="dialog-line actif-container">
          <div class="label" title="Actif">Actif</div>
          <div class="value">
            <dx-check-box
              [(ngModel)]="catalogueAchat.actif"
              #actif="ngModel"
              name="actif"
            >
            </dx-check-box>
          </div>
        </div>

        <div class="dialog-line actif-container">
          <div class="label" title="Produit carné">Produit carné</div>
          <div class="value">
            <dx-check-box
              [(ngModel)]="catalogueAchat.produitCarne"
              #carne="ngModel"
              name="carne"
            >
            </dx-check-box>
          </div>
        </div>
      </div>

      <div class="title-ca">Livré par</div>
      <div class="container-catalogArticle">
        <div class="dialog-line">
          <dx-data-grid
            (onSelectionChanged)="onChangeSelectedFournisseur($event)"
            showRowLines="true"
            showColumnLines="true"
            [dataSource]="fournisseursList"
          >
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="libelle">
            </dxi-column>
            <dxi-column dataField="code">
            </dxi-column>

          </dx-data-grid>
        </div>
      </div>

      <div class="title-ca">Facturation</div>
      <div class="container-catalogArticle">
        <div class="dialog-line">
          <div class="label" title="Unité de facturation">Unité de facturation <span
            class="danger-color">*</span>
          </div>
          <div class="value">
            <yo-lookup
              #uniteDeFacturation
              (onValueChange)="onChange($event, 'uniteDeFacturation')"
              [data]="unitesDeMesure"
              [selectedItem]="catalogueAchat.uniteDeFacturation.id"
            >
            </yo-lookup>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Unité de facturaton / Kg">Unité de facturaton / Kg <span
            class="danger-color">*</span>
          </div>
          <div class="unite-line">
            <span>1 {{catalogueAchat.uniteDeFacturation.libelle}} équivaut à</span>
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.ratioUniteFacturationUniteBase"
              #uniteFacturation="ngModel"
              name="uniteFacturation"
            >
            </dx-text-box>
            <span>Kg</span>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Prix d'achat">Prix d'achat <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.prix"
              #prixAchat="ngModel"
              name="prixAchat"

            >
            </dx-text-box>
            <span> € par {{catalogueAchat.uniteDeFacturation.libelle}} </span>
          </div>
        </div>
      </div>
      <div class="title-ca">Commande</div>
      <div class="container-catalogArticle">
        <div class="dialog-line">
          <div class="label" title="Unité de commande">Unité de commande <span class="danger-color">*</span>
          </div>
          <div class="value">
            <yo-lookup
              #uniteDeCommande
              (onValueChange)="onChange($event, 'uniteDeCommande')"
              [data]="unitesDeMesure"
              [selectedItem]="catalogueAchat.uniteDeCommande.id"
            >
            </yo-lookup>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Unité de commande / unité de facturation">Unité de commande / unité de facturation
            <span
              class="danger-color">*</span>
          </div>
          <div class="unite-line">
            <span>1 {{catalogueAchat.uniteDeCommande.libelle}} équivaut à</span>
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.ratioUniteFacturationUniteBase"
              #uniteCommande="ngModel"
              name="uniteCommande"
            >
            </dx-text-box>
            <span>{{catalogueAchat.uniteDeFacturation.libelle}}</span>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Minimum à commander">Minimum à commander <span class="danger-color">*</span>
          </div>
          <div class="value">
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.minimumDeCommande"
              #minCommande="ngModel"
              name="minCommande"
            >
            </dx-text-box>
            <span> {{catalogueAchat.uniteDeCommande.libelle}}</span>
          </div>
        </div>
      </div>
      <div class="title-ca">Stockage</div>
      <div class="container-catalogArticle">
        <div class="dialog-line">
          <div class="label" title="Unité de stockage">Unité de stockage <span class="danger-color">*</span>
          </div>
          <div class="value">
            <yo-lookup
              #uniteStockage
              [data]="unitesDeMesure"
              (onValueChange)="onChange($event, 'uniteDeStockage')"
              [selectedItem]="catalogueAchat.uniteDeStockage.id"
            >
            </yo-lookup>
          </div>
        </div>

        <div class="dialog-line">
          <div class="label" title="Unité de stockage / unité technique">Unité de stockage / unité technique <span
            class="danger-color">*</span>
          </div>
          <div class="unite-line">
            <span>1 {{catalogueAchat.uniteDeStockage.libelle}} équivaut à</span>
            <dx-text-box
              required
              class='inputfield'
              [(ngModel)]="catalogueAchat.ratioUniteStockageUniteBase"
              #uniteStockageTechnique="ngModel"
              name="uniteStockageTechnique"
            >
            </dx-text-box>
            <span>Kg</span>
          </div>
        </div>
      </div>

      <div *ngIf="catalogueAchat.id !== 0" class="dialog-line">
        <div class="label" title="Allergènes"><span class="title-tagBox">Allergènes</span></div>
        <div class="value">
          <dx-tag-box
            searchEnabled="true"
            searchExpr="libelle"
            [showSelectionControls]="true"
            applyValueMode="useButtons"
            [maxDisplayedTags]="3"
            [multiline]="false"
            [showMultiTagOnly]="false"
            placeholder="Sélection, recherche..."
            searchMode="contains"
            [dataSource]="allergeneList"
            [value]="produitAllergenes"
            displayExpr="libelle"
            valueExpr="id"
            itemTemplate="customItem"
            (onValueChanged)="onAllergenesChange($event)"
          >
            <div *dxTemplate="let product of 'customItem'">
              <div class="custom-item">
                <yo-img-entity entityName="allergene"
                               [entityId]="product.id"
                               [ordre]="1"
                               [height]="48"
                               format="thumb"
                ></yo-img-entity>
                <div class="product-name">
                  {{ product.libelle }}
                </div>
                <div class="product-name">
                  {{ product.code }}
                </div>
              </div>
            </div>
          </dx-tag-box>
        </div>
      </div>

      <div *ngIf="catalogueAchat.id !== 0" class="dialog-line">
        <div class="label" title="Appellations"><span class="title-tagBox">Appellations</span></div>
        <div class="value">
          <dx-tag-box
            searchEnabled="true"
            searchExpr="libelle"
            [showSelectionControls]="true"
            applyValueMode="useButtons"
            [maxDisplayedTags]="3"
            [multiline]="false"
            [showMultiTagOnly]="false"
            placeholder="Sélection, recherche..."
            searchMode="contains"
            [dataSource]="allAppellations"
            [value]="appellationsProduit"
            displayExpr="libelle"
            valueExpr="id"
            itemTemplate="customItem"
            (onValueChanged)="onAppellationsChange($event)"
          >
            <div *dxTemplate="let product of 'customItem'">
              <div class="custom-item">
                <yo-img-entity entityName="appellation"
                               [entityId]="product.id"
                               [ordre]="1"
                               [height]="48"
                               format="thumb"
                ></yo-img-entity>
                <div class="product-name">
                  {{ product.libelle }}
                </div>
                <div class="product-name">
                  {{ product.code }}
                </div>
              </div>
            </div>
          </dx-tag-box>
        </div>
      </div>

      <div class="dialog-line">
        <div class="custom-button-container-right">
          <yo-button
            type="submit"
            iconClass="fas fa-save"
            tooltip="Enregistrer"
            buttonClass="cta-success"
            [disabled]="!isFormValid()">
          </yo-button>
          <yo-button
            buttonClass="cta-delete"
            (onClick)="closeDialog()"
            tooltip="Fermer"
            iconClass="fas fa-times"
          >
          </yo-button>
        </div>
      </div>
    </form>
  </dx-scroll-view>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  width="70vw"
  maxHeight="60vh"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog(false)"
>
  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >
    <form *ngIf="filiale" (ngSubmit)="initSaveFiliale()" #filialeForm="ngForm">
      <div class="custom-fournisseur-form">
        <div class="custom-fournisseur-column">
          <div class="dialog-line">
            <div class="label" title="site">Site <span class="danger-color">*</span></div>
            <div class="value">
              <yo-lookup
                #siteLookup
                [data]="sitesList"
                [selectedItem]="selectedSite"
              >
              </yo-lookup>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
            <div class="value">
              <dx-text-box
                required
                class='inputfield'
                [(ngModel)]="filiale.libelle"
                #libelle="ngModel"
                name="libelle"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Code">Code</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.code"
                #code="ngModel"
                name="code"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Siret">Siret</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.siret"
                #siret="ngModel"
                name="siret"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line actif-container">
            <div class="label" title="Actif">Actif</div>
            <div class="value">
              <dx-check-box
                [(ngModel)]="filiale.actif"
                #actif="ngModel"
                name="actif"
              >
              </dx-check-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Secteur">Secteur <span class="danger-color">*</span></div>
            <div class="value">
              <yo-tagbox
                #secteursTagbox
                [data]="secteurList"
                [selectedItems]="editSelectedSecteurs"
              >
              </yo-tagbox>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label text-nowrap" title="Frais de port">Frais de port</div>
            <div class="value">
              <dx-check-box
                [(ngModel)]="filiale.francoDePortActif"
                #francoDePortActif="ngModel"
                name="francoDePortActif"
              >
              </dx-check-box>
            </div>
          </div>

          <div *ngIf="filiale.francoDePortActif">
            <div class="dialog-line">
              <div class="label text-nowrap" title="Coût des frais de port">Coût des frais de port</div>
              <div class="value">
                <dx-text-box
                  class='inputfield'
                  [(ngModel)]="filiale.francoDePortCout"
                  #francoDePortCout="ngModel"
                  name="francoDePortCout"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label text-nowrap" title="Montant HT minimal">Montant HT minimal</div>
              <div class="value">
                <dx-text-box
                  class='inputfield'
                  [(ngModel)]="filiale.francoDePortMontant"
                  #francoDePortMontant="ngModel"
                  name="francoDePortMontant"
                >
                </dx-text-box>
              </div>
            </div>


            <div class="dialog-line">
              <div class="label text-nowrap" title="Poids minimal">Poids minimal</div>
              <div class="value">
                <dx-text-box
                  class='inputfield'
                  [(ngModel)]="filiale.francoDePortPoids"
                  #francoDePortPoids="ngModel"
                  name="francoDePortPoids"
                >
                </dx-text-box>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-fournisseur-column">
          <div class="dialog-line">
            <div class="label" title="Nom de l'adresse">Nom de l'adresse</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.nom"
                #nom="ngModel"
                name="nom"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label text-nowrap" title="Adresse 1">Adresse 1</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.adresse1"
                #adresse1="ngModel"
                name="adresse1"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label text-nowrap" title="Adresse 2">Adresse 2</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.adresse2"
                #adresse2="ngModel"
                name="adresse2"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label text-nowrap" title="Adresse 3">Adresse 3</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.adresse3"
                #adresse3="ngModel"
                name="adresse3"
              >

              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label text-nowrap" title="Code Postal">Code Postal</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.codePostal"
                #codePostal="ngModel"
                name="codePostal"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Ville">Ville</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.ville"
                #ville="ngModel"
                name="ville"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Téléphone">Téléphone</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.telephone"
                #telephone="ngModel"
                name="telephone"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Fax">Fax</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.fax"
                #fax="ngModel"
                name="fax"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="dialog-line">
            <div class="label" title="Email">Email</div>
            <div class="value">
              <dx-text-box
                class='inputfield'
                [(ngModel)]="filiale.adresse.email"
                #email="ngModel"
                name="email"
              >
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-line">
        <div class="custom-button-container-right">
          <yo-button *ngIf="isVisibleSaveBtn()"
                     buttonClass="cta-success"
                     (onClick)="initSaveFiliale()"
                     iconClass="fas fa-save"
                     [disabled]="!isFormValid()">

          </yo-button>
          <yo-button iconClass="fas fa-times"
                     buttonClass="cta-delete"
                     (onClick)="closeDialog(false)">

          </yo-button>
        </div>
      </div>
    </form>
  </dx-scroll-view>
</dx-popup>
